/* eslint-disable import/prefer-default-export */

import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import axios from '../../../axios';
import { ObjectResponse, Country, OptionItem } from '../../../types';
import { translateNameI18n } from '../../../utils/i18nUtils';
import { arrayToIndexObject } from '../../../utils/objectUtils';

const endpointUrl = '/api/v1/countries';

export const useCountryStore = defineStore('country', {
    state: () => ({
        countries: {} as { [key: string]: Country },
        isFetchingCountries: false,
    }),
    actions: {
        async fetchCountries() {
            try {
                this.isFetchingCountries = true;
                const response: AxiosResponse<ObjectResponse> = await axios.get(`${endpointUrl}`);
                const countries = response.data.data as Country[];
                translateNameI18n(countries);
                this.countries = arrayToIndexObject(countries, 'code');

                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isFetchingCountries = false;
            }
        },
    },
    getters: {
        getCountries(state) {
            return () => Object.values(state.countries);
        },
        getCountryByCode(state) {
            return (code: string) => state.countries[code];
        },
        getCountriesAsOptions() {
            return () =>
                this.getCountries().map(
                    (country: Country) =>
                        ({
                            id: country.code,
                            name: country.name,
                        }) as OptionItem
                );
        },
    },
});
